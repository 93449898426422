import React, { useState, useEffect, startTransition } from "react";
import { Text, Container, Button } from "@atoms";
import Pattern from "@svg/HeroPatternAlt2";
import { m, AnimatePresence } from "framer-motion";

const HeroHome = ({ heading, descriptor, buttons, terms }) => {
  const [active, setActive] = useState(0);
  const activeTerm = terms?.[active];
  const amount = terms.length || 0;

  useEffect(() => {
    if (active < amount - 1)
      startTransition(() => setTimeout(() => setActive(active + 1), 2400));
  }, [active]);

  return (
    <div className="relative mb-10 bg-purple pb-32 pt-10 sm:mb-20 sm:pb-44 sm:pt-16 md:mb-24">
      <Container
        padding
        className="relative flex h-full flex-col justify-center pb-1"
      >
        <div className="text-center">
          <Text variant="h1large" className="font-bold text-white">
            {heading}
          </Text>

          <div className="relative grid w-full grid-cols-1 items-center justify-center overflow-hidden">
            <AnimatePresence mode="popLayout" initial={false}>
              {/* crossfading terms */}
              {activeTerm && (
                <m.span
                  key={activeTerm}
                  initial={{ opacity: 0, x: 1500 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -1500 }}
                  transition={{ duration: 0.8 }}
                  className="col-span-full col-start-1 row-start-1"
                >
                  <Text
                    key={activeTerm}
                    variant="h1large"
                    className="text-yellow"
                  >
                    {activeTerm}
                  </Text>
                </m.span>
              )}
            </AnimatePresence>
          </div>

          <Text
            variant="lg"
            className="mx-auto mb-10 mt-6 max-w-sm pr-0 text-center font-bold text-white"
            dewidow
            richText
          >
            {descriptor}
          </Text>

          {!!buttons?.length && (
            <div className="flex flex-wrap justify-center flex-gap-4">
              {buttons?.map((b, i) => {
                const { url, text } = b.button;
                return (
                  <span>
                    <Button
                      key={`heroButton--${url}-${i + 1}`}
                      color={i === 0 ? "yellow" : "yellowTransparent"}
                      outline
                      to={url}
                      size="sm"
                    >
                      <Text>{text}</Text>
                    </Button>
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </Container>

      {/* pattern */}
      <Pattern className="pointer-events-none absolute inset-x-[-25%] bottom-0 z-0 mx-auto w-[200%] max-w-8xl text-white sm:w-[150%] md:inset-x-[-1.25%] md:w-[102.5%]" />
    </div>
  );
};

HeroHome.defaultProps = {};

export default HeroHome;
