import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";

const draw = {
  hidden: { pathLength: 0 },
  visible: (time = 0) => {
    const delay = time * 2.15;
    return {
      pathLength: 1,
      transition: {
        delay,
        ease: "easeInOut",
        duration: 1.5,
      },
    };
  },
};

const HeroPatternAlt2 = React.memo(({ fitHeight, className: _className }) => {
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        width="1450"
        height="416"
        viewBox="0 0 1450 416"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 sm:stroke-1"
        viewport={{
          once: true,
        }}
      >
        {/* 7 */}
        <m.path
          d="M1407.95 297.125L1364.69 297.125C1316.38 297.125 1277.22 257.964 1277.22 209.657C1277.22 206.289 1279.95 203.559 1283.32 203.559L1407.8 203.559C1413.04 203.559 1417.3 207.812 1417.3 213.059L1417.3 287.783C1417.3 292.942 1413.11 297.125 1407.95 297.125Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={7}
        />
        <m.rect
          x="1449.3"
          y="239.5"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          transform="rotate(90 1449.3 239.5)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={7}
        />
        <m.path
          d="M1292.8 326.968L1292.8 328.411C1292.8 331.838 1290.02 334.616 1286.59 334.616L1208.73 334.616C1203.48 334.616 1199.23 330.363 1199.23 325.116L1199.23 245.598C1199.23 242.23 1201.96 239.5 1205.33 239.5C1253.63 239.5 1292.8 278.661 1292.8 326.968Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={7}
        />

        {/* 6 */}
        <m.path
          d="M1115.32 289.059L1323.06 289.059C1327.54 289.059 1331.16 292.684 1331.16 297.156C1331.16 344.359 1292.9 382.625 1245.69 382.625L1115.32 382.625C1110.08 382.625 1105.82 378.371 1105.82 373.125L1105.82 298.559C1105.82 293.312 1110.08 289.059 1115.32 289.059Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={6}
        />
        <m.path
          d="M997.931 258.5H1041.19C1089.5 258.5 1128.66 297.661 1128.66 345.968C1128.66 349.336 1125.93 352.066 1122.57 352.066H998.089C992.842 352.066 988.589 347.813 988.589 342.566V267.842C988.589 262.683 992.772 258.5 997.931 258.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={6}
        />

        {/* 5 */}
        <m.rect
          x="948.501"
          y="383.838"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 948.501 383.838)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={5}
        />
        <m.path
          d="M857.411 415.5H900.674C948.982 415.5 988.143 376.339 988.143 328.032C988.143 324.664 985.413 321.934 982.045 321.934H857.569C852.322 321.934 848.069 326.187 848.069 331.434V406.158C848.069 411.317 852.251 415.5 857.411 415.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={5}
        />

        {/* 4 */}
        <m.path
          d="M815.064 341.085V267.304C815.064 262.057 819.318 257.804 824.564 257.804H896.594C903.241 257.804 908.631 263.193 908.631 269.84V271.189C908.631 316.328 872.038 352.92 826.899 352.92C820.363 352.92 815.064 347.621 815.064 341.085Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={4}
        />
        <m.path
          d="M731 289.365H805.566C810.813 289.365 815.066 293.619 815.066 298.865V373.431C815.066 378.678 810.813 382.931 805.566 382.931H731C725.753 382.931 721.5 378.678 721.5 373.431V298.865C721.5 293.619 725.753 289.365 731 289.365Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={4}
        />

        {/* 3 */}
        <m.path
          d="M762.105 415.5H595.402C591.004 415.5 587.426 411.959 587.379 407.562C586.881 360.418 624.96 321.934 672.106 321.934H762.413C767.964 321.934 772.447 326.465 772.388 332.015L771.605 406.1C771.55 411.308 767.313 415.5 762.105 415.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={3}
        />
        <m.path
          d="M602.469 351.932H603.911C607.338 351.932 610.117 349.153 610.117 345.726V267.866C610.117 262.619 605.863 258.366 600.617 258.366H521.098C517.73 258.366 515 261.096 515 264.463C515 312.771 554.161 351.932 602.469 351.932Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={3}
        />

        {/* 2 */}
        <m.path
          d="M280.297 352.064H443.324C449.759 352.064 454.994 346.884 455.062 340.45C455.539 295.33 419.095 258.498 373.973 258.498H293.798C280.645 258.498 270.021 269.235 270.16 282.387L270.798 342.665C270.853 347.872 275.09 352.064 280.297 352.064Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={2}
        />
        <m.path
          d="M463.032 383.072H461.589C458.162 383.072 455.384 380.294 455.384 376.866V299.006C455.384 293.759 459.637 289.506 464.884 289.506H544.402C547.77 289.506 550.5 292.236 550.5 295.604C550.5 343.911 511.339 383.072 463.032 383.072Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={2}
        />

        {/* 1 */}
        <m.path
          d="M308.656 311.72V268.457C308.656 220.149 269.495 180.988 221.188 180.988C217.82 180.988 215.09 183.718 215.09 187.086V311.563C215.09 316.809 219.343 321.062 224.59 321.062H299.314C304.474 321.062 308.656 316.88 308.656 311.72Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={1}
        />
        <m.path
          d="M402.5 376.527L402.5 377.969C402.5 381.397 399.722 384.175 396.294 384.175L318.434 384.175C313.187 384.175 308.934 379.922 308.934 374.675L308.934 295.156C308.934 291.789 311.664 289.059 315.032 289.059C363.339 289.059 402.5 328.22 402.5 376.527Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={1}
        />

        {/* 0 */}
        <m.path
          d="M181.489 383.074H131.824C83.5166 383.074 44.3556 343.913 44.3556 295.606C44.3556 292.238 47.0856 289.508 50.4532 289.508H174.93C180.176 289.508 184.43 293.761 184.43 299.008L184.43 380.133C184.43 381.758 183.113 383.074 181.489 383.074Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={0}
        />
        <m.path
          d="M8.1487 0.539063L6.70658 0.539063C3.27921 0.539063 0.500786 3.31749 0.500786 6.74486L0.500786 84.6052C0.500786 89.8519 4.75408 94.1052 10.0008 94.1052L89.5196 94.1052C92.8872 94.1052 95.6172 91.3752 95.6172 88.0076C95.6172 39.7 56.4562 0.539063 8.1487 0.539063Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={0}
        />
        <m.path
          d="M59.1214 132.539H57.6792C54.2519 132.539 51.4734 135.317 51.4734 138.745V216.605C51.4734 221.852 55.7267 226.105 60.9734 226.105H140.492C143.86 226.105 146.59 223.375 146.59 220.008C146.59 171.7 107.429 132.539 59.1214 132.539Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={0}
        />
        <m.path
          d="M96.0664 310.012L96.0664 102.27C96.0664 97.7982 92.4413 94.1731 87.9695 94.1731C40.7661 94.1731 2.50027 132.439 2.50027 179.642L2.50027 310.012C2.50027 315.258 6.75357 319.512 12.0003 319.512H86.5664C91.8131 319.512 96.0664 315.258 96.0664 310.012Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={0}
        />
        <m.path
          d="M215.438 309.228V235.446C215.438 230.2 211.184 225.946 205.938 225.946H133.908C127.26 225.946 121.871 231.335 121.871 237.983V239.331C121.871 284.47 158.464 321.063 203.603 321.063C210.139 321.063 215.438 315.764 215.438 309.228Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          custom={0}
        />
      </m.svg>
    </span>
  );
});

export default HeroPatternAlt2;
